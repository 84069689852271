import { getDateFormatHumano, getDateDMYJS } from './date.util';

const ordinales = require('ordinales-js');
const { numToText } = require('numero-a-texto');
const { arabToRoman } = require('roman-numbers');

const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];

const montoLiteral = (monto) => {
    const parteEntera = parseInt(monto);
    const parteDecimal = Math.round((monto % 1) * 100);

    let enteroLiteral = numToText.translate(parteEntera).toUpperCase();
    let decimalLiteral = "";
    if (parteDecimal == 0.0) {
        decimalLiteral = "00/100";
    } else {
        decimalLiteral = `${parteDecimal}/100`;
    }

    return `${enteroLiteral} ${decimalLiteral}`;
};

const toUpperFirstCase = (texto) => {

    return texto.split(' ').map(p => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase()).join(' ');
};

export const programaToJsonData = (programa, modulos, costos) => {
    const jsonData = {
        programa: programa.nombre,
        version: ordinales.toOrdinal(programa.version, 'f').replace(/^\w/, (letra) => letra.toUpperCase()),
        versionRomano: arabToRoman(programa.version),
        certificadoAcademico: programa.certificadoAcademico,
        unidadEjecucion: programa.unidadResponsable,
        convenios: programa.programaConvenios.map((item) => ({ nombre: item.convenio.nombre })),
        sede: programa.sedeEjecucion,   
        subSedes: programa.programaSedes.map(item => item.sede),
        // duracionSemanas: programa.duracionColegiatura,
        duracionMeses: numToText.translate(parseInt(programa.duracionColegiatura)).toLowerCase(),
        responsableEjecucion: programa.responsableEjecucion,
        objetivoGeneral: programa.objetivoGeneral,

        costoTotal: costos.costoTotal,
        costoMatricula: costos.costoMatricula,
        costoColegiatura: costos.costoColegiatura,
        costoDefensaTutoria: costos.costoDefensaTutoria,
        descuentos: costos.programaDescuentos.map((item) => ({
            nombre: `${item.nombre} del ${item.descuento}%`,
            monto: costos.costoColegiatura - (costos.costoColegiatura * item.porcentaje / 100)
        })),
        lugarDesarrollo: 'A nivel Nacional',
        modalidadEjecucion: toUpperFirstCase(programa.modalidad.nombre),
        requisitos: programa.requisitosAdmision,

        modulos: modulos.map((modulo, indice) => ({
            numero: arabToRoman(indice + 1),
            nombre: modulo.nombre,
            cantidadAsignaturas: modulo.asignaturas.length + 1,
            asignaturas: modulo.asignaturas.map((asignatura) => ({
                nombre: asignatura.nombre,
                docente: asignatura.persona ? `${asignatura.persona.nombres} ${asignatura.persona.apellidoPaterno} ${asignatura.persona.apellidoMaterno}` : null,
            }))
        })),

        tablaModulos: null,

        investigacion: toUpperFirstCase(programa.trabajoInvestigacion.nombre),
        graduacion: toUpperFirstCase(programa.graduacion.nombre),
        creditos: programa.totalCreditos,
    };

    let tablaModulos = `<table>
                            <thead>
                                <tr>
                                    <th align="center" width="10%"> </th>
                                    <th align="center">MÓDULO</th>
                                    <th align="center">ASIGNATURA</th>
                                    
                                </tr>
                            </thead>
                            <tbody>`;

    jsonData.modulos.forEach(modulo => {
        tablaModulos += `<tr>
                            <td align="center" rowspan="${modulo.cantidadAsignaturas}">${modulo.numero}</td>
                            <td align="center" rowspan="${modulo.cantidadAsignaturas}">${modulo.nombre}</td>                
                        </tr>`;
        modulo.asignaturas.forEach(asignatura => {
            tablaModulos += `<tr>
                                <td align="left">${asignatura.nombre}</td>
                                
                            </tr>`;
        });
    });

    tablaModulos += `</tbody>
                    </table>`;

    jsonData.tablaModulos = tablaModulos;

    return jsonData;
};

export const jsonDataContrato = (inscripcion, programa, costos, planPagos, firmas) => {
    let denominativo = 'el señor';

    const persona = inscripcion.estudiante.persona;

    const fechaInicio = new Date(programa.fechaInicio);
    const fechaFin = new Date(programa.fechaFin);

    const mesInicio = fechaInicio.getMonth();
    const gestionInicio = fechaInicio.getFullYear();

    const mesFin = fechaFin.getMonth();
    const gestionFin = fechaFin.getFullYear();

    if (inscripcion.estudiante.persona.sexo != 'Masculino')
        denominativo = 'la señora';

    const nombresApellidos = `${persona.nombres} ${persona.apellidoPaterno || ''} ${persona.apellidoMaterno || ''}`;

    const jsonData = {
        numeroContrato: inscripcion.nroContrato,

        denominativo: denominativo,
        nombrePersona: toUpperFirstCase(nombresApellidos),
        numeroDocumento: persona.numeroIdentificacion,
        profesion: inscripcion.gradoAcademico.nombre,

        costoTotal: costos.costoTotal,
        costoTotalTexto: montoLiteral(costos.costoTotal),
        costoMatricula: costos.costoMatricula,
        costoMatriculaTexto: montoLiteral(costos.costoMatricula),
        colegiatura: costos.costoColegiatura,
        costoColegiaturaTexto: montoLiteral(costos.costoColegiatura),
        costoDefensaTutoria: costos.costoDefensaTutoria,
        costosDefensaTutoriaTexto: montoLiteral(costos.costoDefensaTutoria),

        // nombreDescuento: inscripcion.programaDescuento.nombre
        // porcentajeDescuento: inscripcion.programaDescuento.porcentaje,

        programa: programa.nombre,
        version: ordinales.toOrdinal(programa.version, 'f').replace(/^\w/, (letra) => letra.toUpperCase()),
        versionRomano: arabToRoman(programa.version),
        modalidadEjecucion: toUpperFirstCase(programa.modalidad.nombre),
        trabajoInvestigacion: toUpperFirstCase(programa.trabajoInvestigacion.nombre),
        graduacion: toUpperFirstCase(programa.graduacion.nombre),
        certificadoAcademico: programa.certificadoAcademico,

        // duracionSemanas: programa.duracionColegiatura,
        duracionMeses: parseInt(programa.duracionColegiatura),

        mesInicio: meses[mesInicio],
        gestionInicio: numToText.translate(parseInt(gestionInicio, 10)).toUpperCase(),
        duracionMesesTexto: numToText.translate(parseInt(programa.duracionColegiatura)).toUpperCase(),
        mesFin: meses[mesFin],
        gestionFin: numToText.translate(parseInt(gestionFin, 10)).toUpperCase(),

        // modalidadPago: inscripcion.modalidadPago,
        // numeroCuotas: inscripcion.numeroCuotas,
        // numeroCuotasTexto: numToText.translate(parseInt(programa.numeroCuotas, 10)).toUpperCase(),
        // diaLimitePago: inscripcion.diaPago,
        // montoCuota: montoCuota,
        // montoCuotaTexto: numToText.translate(parseInt(montoCuota, 10)).toUpperCase(),
        // montoPrimerPago: primerPago,
        // montoPrimerPagoTexto: numToText.translate(parseInt(primerPago, 10)).toUpperCase(),

        modalidadPago: planPagos.modalidad,
        porcentajeDescuento: planPagos.descuento.toString(),
        colegiaturaDescuento: planPagos.colegiaturaDescuento.toString(),
        costoColegiaturaDescuentoTexto: montoLiteral(planPagos.colegiaturaDescuento),
        numeroCuotas: planPagos.numeroCuotas.toString(),
        numeroCuotasTexto: numToText.translate(planPagos.numeroCuotas).toUpperCase(),
        diaLimitePago: planPagos.diaLimitePago.toString(),
        montoCuota: planPagos.montoCuota.toString(),
        montoCuotaTexto: montoLiteral(planPagos.montoCuota),
        montoPrimerPago: planPagos.montoPrimerPago.toString(),
        montoPrimerPagoTexto: montoLiteral(planPagos.montoPrimerPago),
        multaDefensaTutoria: planPagos.multaDefensaTutoria.toString(),

        fechaLiteral: getDateFormatHumano(new Date()),
        firmas: firmas ? firmas : '',
    };

    return jsonData;
};

export const jsonDataFormInscripcion = (programa, inscripcion) => {
    const jsonData = {
        programa: programa.nombre,
        version: ordinales.toOrdinal(programa.version, 'f').replace(/^\w/, (letra) => letra.toUpperCase()),
        versionRomano: arabToRoman(programa.version),
        modalidadEjecucion: toUpperFirstCase(programa.modalidad.nombre),

        nombres: toUpperFirstCase(inscripcion.nombres),
        apellidoPaterno: inscripcion.apellidoPaterno ? toUpperFirstCase(inscripcion.apellidoPaterno) : '',
        apellidoMaterno: inscripcion.apellidoMaterno ? toUpperFirstCase(inscripcion.apellidoMaterno) : '',
        numeroDocumento: `${inscripcion.documento}`,
        sexo: inscripcion.sexo,
        paisNacimiento: inscripcion.paisOrigen.nombre,
        departamentoNacimiento: inscripcion.departamentoOrigen.nombre,
        fechaNacimiento: getDateDMYJS(inscripcion.fechaNacimiento),
        estadoCivil: inscripcion.estadoCivil.nombre,

        direccion: inscripcion.direccion,
        telefono: inscripcion.telefono,
        celular: inscripcion.celular,
        correo: inscripcion.correo,
        paisResidencia: inscripcion.paisResidencia.nombre,
        departamentoResidencia: inscripcion.departamentoResidencia.nombre,

        universidad: inscripcion.universidad.nombre,
        gradoObtenido: inscripcion.gradoObtenido.nombre,
        fechaAcademico: getDateDMYJS(inscripcion.fechaDiploma),
        fechaTitulación: getDateDMYJS(inscripcion.fechaTitulo),
    };

    return jsonData;
};